<template>
    <el-dialog :visible.sync="formDialog" title="编辑" :close-on-click-modal="false" @close="closeForm">
        <el-form ref="dataForm" :model="dataForm" :rules="ruleForm" label-width="100px" class="data-form">
            <el-form-item label="角色名" prop="name">
                <el-input v-model="dataForm.name" placeholder="角色名"></el-input>
            </el-form-item>
            <el-form-item label="权限" prop="auth_ids">
                <el-tree :data="auth_ids" ref="auth_ids" :props="props" node-key="id" show-checkbox default-expand-all highlight-current @check-change="handleCheckChange"></el-tree>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
                <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import { getRoleInfo, editRole } from '@/utils/servers/adminRole.js';
import { getCheckAuth } from '@/utils/servers/adminAuth.js';
export default {
    props: ['editFormDialog', 'editFormId'],
    data() {
        return {
            formDialog: this.editFormDialog,
            auth_ids: [],
            props: {
                label: 'name',
                children: 'children'
            },
            dataForm: {
                id: 0,
                name: '',
                role_auth_ids: ''
            },
            ruleForm: {
                name: [{ required: true, trigger: 'blur' }],
                auth_ids: [{ required: true, trigger: 'blur' }],
            }
        };
    },
    created() {
        this.getRoleInfo();
        this.getCheckAuth();
    },
    methods: {
        handleCheckChange() {
            this.dataForm.role_auth_ids = this.$refs.auth_ids
                .getCheckedNodes()
                .map(item => item.id)
                .join(',');
        },
        getCheckAuth() {
            getCheckAuth().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.auth_ids = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getRoleInfo() {
            getRoleInfo(this.editFormId).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.dataForm.id = data.data.id;
                    this.dataForm.name = data.data.name;
                    this.dataForm.role_auth_ids = data.data.role_auth_ids;
                    this.$refs.auth_ids.setCheckedKeys(this.dataForm.role_auth_ids);
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        submitForm() {
            editRole(this.dataForm.id, this.dataForm).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.$message.success(data.msg);
                    this.closeForm();
                    this.$emit('getRoleList');
                    setInterval(() => {
                        location.reload();
                    }, 300);
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        resetForm() {
            this.$refs.dataForm.resetFields();
        },
        closeForm() {
            this.$emit('closeForm', 'edit');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
}
</style>
